import { Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";


const HomeChristmas2023 = lazy(() => import("./pages/home/HomeChristmas2023"));

const App = () => {
  return (
      <Router>
        <ScrollToTop>
          <Suspense
            fallback={
              <div className="flone-preloader-wrapper">
                <div className="flone-preloader">
                  <span></span>
                  <span></span>
                </div>
              </div>
            }
          >
            <Routes>
              <Route
                  path={process.env.PUBLIC_URL + "/"}
                  element={<HomeChristmas2023/>}
              />
              {/*<Route path="*" element={<NotFound/>} />*/}
            </Routes>
          </Suspense>
        </ScrollToTop>
      </Router>
  );
};

export default App;